// Core
import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/all"
import { SplitText } from "gsap/SplitText"
import { isMobile } from "react-device-detect"
import { maskOut, maskIn } from "./../utils/masks"
import { localiseString } from "./../utils/localiseString"

// Components
import SEO from "../components/seo"
import Img from "gatsby-image"
import Video from "../components/video"
import ScrollSection from "../components/locomotiveScroll/scrollSection"
import JsonText from "../components/_jsonText"
import Footer from "../components/footer"

// Style
import styles from "./home.module.scss"

// Images
import Soundstage from "./images/soundstage.inline.svg"
import SoundstageWide from "./images/soundstagewide.inline.svg"
import { SlideMaskText } from "../components/slideMaskText"

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollToPlugin)

const IndexPage = props => {
  const [loaded, setLoaded] = useState(false)
  const [playLatestVideo, setPlayLatestVideo] = useState(false)
  const [slideUp, setSlideUp] = useState(false)
  const [heroHeight, setHeroHeight] = useState(false)
  const homeContent = useRef(null)
  const episodeVideo = useRef(null)
  const {
    seoTitle,
    seoDescription,
    seoImage,
    intro,
  } = props.data.allContentfulHomePage.nodes[0]
  const latestEp = props.data.allContentfulEpisode.nodes
    .sort((epA, epB) =>
      epA.seasonNumber < epB.seasonNumber
        ? 1
        : epB.seasonNumber < epA.seasonNumber
        ? -1
        : 0
    )
    .find(ep => ep.episodeScheduler && ep.heroPosterImage)

  const {
    title,
    slug,
    seasonNumber,
    episodeNumber,
    heroPosterLoop,
    heroPosterImage,
  } = latestEp
  const hero = useRef()
  const latestEpisodeRef = useRef()
  let windowScrollY = 0
  let scrollTicking = false
  let pageScrolled = useRef(false)
  let slideUpRef = useRef(false)
  let scrollRAF = false
  const header = useRef(null)

  const isLoaded = () => {
    setLoaded(true)
  }

  const scrollToContent = () => {
    if (isMobile) {
      gsap.to(window, { duration: 1, scrollTo: homeContent.current })
    } else {
      window.scroll.scrollTo(homeContent.current)
    }
  }

  const scrolledHero = way => {
    if (way === "exit" && !pageScrolled.current) {
      console.log("down")
      pageScrolled.current = true
      if (header.current)
        header.current.setAttribute("scrolled", pageScrolled.current)
    } else if (way === "enter" && pageScrolled.current) {
      console.log("up")
      pageScrolled.current = false
      if (header.current)
        header.current.setAttribute("scrolled", pageScrolled.current)
    }
  }

  const toLatestEpisode = () => {
    const header = document.getElementById("header"),
      wrapper = document.querySelector(".tl-wrapper-status--entered")

    wrapper.style.opacity = 0
    wrapper.style.pointerEvents = "none"
    if (header.current) header.current.setAttribute("scrolled", true)

    if (isMobile) {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: latestEpisodeRef.current },
      })
    } else {
      window.scroll.scrollTo(latestEpisodeRef.current)
    }
  }

  const showLatestVideo = () => {
    if (!episodeVideo.current) return
    setPlayLatestVideo(true)
    gsap.to(episodeVideo.current, {
      opacity: 1,
      duration: 0.3,
      delay: 0.1,
      ease: "power2.out",
    })
  }

  const hideLatestVideo = () => {
    if (!episodeVideo.current) return
    gsap.to(episodeVideo.current, {
      opacity: 0,
      duration: 0.7,
      ease: "power2.in",
      onComplete: () => {
        setPlayLatestVideo(false)
      },
    })
  }

  const scrollHeroAnimation = () => {
    const currentScrollY = windowScrollY

    if (currentScrollY >= 50 && !slideUpRef.current) {
      setSlideUp(true)
      slideUpRef.current = true
    } else if (currentScrollY < 50 && slideUpRef.current) {
      setSlideUp(false)
      slideUpRef.current = false
    }

    scrollTicking = false
  }

  const scrollTicker = () => {
    if (!scrollTicking) {
      scrollRAF = window.requestAnimationFrame(scrollHeroAnimation)
    }
    scrollTicking = true
  }

  const onScroll = () => {
    windowScrollY = window.scrollY
    scrollTicker()
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isMobile && window.scroll.update) {
        window.scroll.update()
      }
    }, 200)

    if (!isMobile && window.scroll.update) window.scroll.update()

    maskIn()

    header.current = document.getElementById("header")
    if (header.current) header.current.setAttribute("scrolled", false)

    if (isMobile) {
      setHeroHeight(window.innerHeight * 0.9)

      window.addEventListener("scroll", onScroll)
    }

    setTimeout(() => {
      if (!isMobile) {
        window.scroll.on("call", (value, way) => {
          if (value === "hero") scrolledHero(way)
        })
      }
    }, 200)

    return () => {
      if (isMobile) {
        window.removeEventListener("scroll", onScroll)
        if (scrollRAF) {
          window.cancelAnimationFrame(scrollRAF)
        }
      }
    }
  }, [])

  return (
    <>
      <ScrollSection zindex={1}>
        <div
          className={`${styles.heroWrapper} ${
            isMobile ? styles.isMobile : ""
          } ${isMobile && slideUp ? styles.slideUp : ""}`}
          data-scroll
          data-scroll-speed="4"
          data-scroll-call="hero"
          data-scroll-offset="0, 10%"
        >
          <section
            className={`${styles.hero} ${loaded ? styles.loaded : ""}`}
            ref={hero}
            style={{ height: heroHeight ? heroHeight : "" }}
          >
            <SEO
              fullTitle={seoTitle}
              description={seoDescription.seoDescription}
              image={seoImage}
            />

            <div className={styles.video}>
              <div className={styles.videoInner}>
                <Video
                  vidID="511800988"
                  playState
                  autoplay
                  fill
                  stretch
                  loop
                  muted
                  loadCallback={isLoaded}
                />
              </div>
            </div>

            <div className={styles.soundstage}>
              <p className={`${styles.mono} mono`}>
                {localiseString("LA Phil")}
                <br /> {localiseString("Media Presents")}
              </p>

              <div className={styles.soundstageSvg}>
                <Soundstage />
                <SoundstageWide />
              </div>

              <div className={styles.latestLink}>
                <TransitionLink
                  className={`button button--filled`}
                  title={localiseString("Explore the series")}
                  to={`/episodes`}
                  exit={{
                    trigger: ({ exit, node }) => maskOut(),
                    length: 1.5,
                    zIndex: 2,
                  }}
                  entry={{
                    trigger: () => {
                      if (!isMobile) window.scroll.update()
                    },
                    delay: 1.5,
                    zIndex: 1,
                  }}
                >
                  <SlideMaskText text={localiseString("Explore the series")} />
                </TransitionLink>
              </div>
            </div>

            <button className={styles.heroScrollCta} onClick={scrollToContent}>
              <SlideMaskText text={localiseString("Discover more")} />

              <svg
                width="19"
                height="12"
                viewBox="0 0 19 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1l8.5 9L18 1" stroke="#E6E5E1" strokeWidth="2" />
              </svg>
            </button>
          </section>

          <div className={styles.heroGradient}></div>
        </div>
      </ScrollSection>

      <ScrollSection>
        <section
          className={`${styles.intro} ${loaded ? styles.loaded : ""}`}
          ref={homeContent}
        >
          <div className="container">
            <div className={styles.introText}>
              <JsonText {...intro} />
            </div>

            <TransitionLink
              className={styles.introLink}
              to={`/about`}
              exit={{
                delay: 1,
                length: 1,
                zIndex: 2,
              }}
              entry={{
                trigger: () => {
                  if (!isMobile) window.scroll.update()
                },
                delay: 1,
                length: 0,
                zIndex: 1,
              }}
              onClick={() => maskOut()}
            >
              <SlideMaskText text={localiseString("About Sound/Stage")} />
            </TransitionLink>
          </div>
        </section>
      </ScrollSection>

      <ScrollSection>
        <section
          className={`${styles.latestEpisodeContainer} container`}
          ref={latestEpisodeRef}
        >
          <div className={`cols flex flex--center`}>
            <div className={`col m12 tp10`}>
              <TransitionLink
                className={styles.latestEpisode}
                to={`/${slug}`}
                onClick={toLatestEpisode}
                onMouseEnter={showLatestVideo}
                onMouseLeave={hideLatestVideo}
                exit={{
                  delay: 1,
                  length: 0,
                  zIndex: 2,
                }}
                entry={{
                  delay: 0,
                  length: 0,
                  zIndex: 1,
                }}
              >
                <div className={styles.latestEpisodeWrapper}>
                  <Img
                    alt={heroPosterImage.description}
                    fluid={heroPosterImage.fluid}
                    style={{ position: "absolute", top: 0, left: 0 }}
                  />

                  {heroPosterLoop && (
                    <div
                      className={styles.latestEpisodeVideo}
                      ref={episodeVideo}
                    >
                      <Video
                        vidID={heroPosterLoop}
                        playState={playLatestVideo}
                        autoplay
                        loop
                        fill
                        stretch
                        muted
                      />
                    </div>
                  )}

                  <div className={styles.latestEpisodeContent}>
                    <h3 className={styles.latestEpisodeTitle}>
                      {localiseString("Latest episode")}
                    </h3>
                    <p className={styles.latestEpisodeNumber}>
                      <strong>
                        {localiseString("Season")} {seasonNumber}
                      </strong>{" "}
                      - {localiseString("EP")}
                      {episodeNumber}
                    </p>
                    <h4 className={styles.latestEpisodeName}>{title}</h4>
                  </div>
                </div>
              </TransitionLink>
            </div>
          </div>
        </section>
      </ScrollSection>

      <Footer />
    </>
  )
}

export default IndexPage

export const homeQuery = graphql`
  query HomePage {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHomePage(limit: 1) {
      nodes {
        intro {
          json
        }
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          fluid(
            maxWidth: 1200
            maxHeight: 630
            cropFocus: CENTER
            quality: 85
            resizingBehavior: FILL
          ) {
            src
          }
          description
        }
      }
    }
    allContentfulEpisode(
      sort: { order: DESC, fields: episodeNumber }
      filter: { slug: { ne: "sample" } }
    ) {
      nodes {
        slug
        title
        seasonNumber
        episodeNumber
        episodeScheduler {
          title
        }
        heroPosterLoop
        heroPosterImage {
          description
          fluid(maxWidth: 2130) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
